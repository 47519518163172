import { dateToStringInFormat } from 'cs-alpha-utils/utils/date-utils';
import { FC } from 'react';

import DataList from 'components/UI/DataList';
import Divider from 'components/UI/Divider';
import Heading, { Type } from 'components/UI/Heading';
import { IconType } from 'components/UI/Icon';
import PageState from 'components/UI/Page/PageState';
import { InformationSection } from 'components/UI/Product/ProductInformation/styled';
import StatusMarker from 'components/UI/StatusMarker';
import { useTranslations } from 'hooks/useTranslations';
import { ServiceContract } from 'models/product';
import { getContractStatusColor } from 'utils/productUtils';
import { titleCase } from 'utils/stringUtils';

import ProductServiceTermsAndConditions from 'components/UI/Product/ProductServiceTermsAndConditions';

import { Container, ContractStatus, TocContainer } from './styled';

type Props = {
  serviceContracts: ServiceContract[];
  languageCode?: string;
};

const ProductServices: FC<Props> = ({ serviceContracts, languageCode }) => {
  const t = useTranslations();

  // Empty state
  if (!serviceContracts.length) {
    return (
      <PageState
        iconType={IconType.Products}
        title={t('productdetails.services_empty')}
        small
      />
    );
  }

  return (
    <Container>
      {serviceContracts.map((serviceContract, index) => {
        const isLast = index === serviceContracts.length - 1;
        const type = titleCase(serviceContract.type);

        return (
          <InformationSection key={serviceContract.id}>
            <Heading type={Type.h2}>
              {serviceContract.marketName || type}
            </Heading>
            <DataList
              data={[
                {
                  name: t('productdetails.data_service_id'),
                  value: serviceContract.id
                },
                {
                  name: t('productdetails.data_service_type'),
                  value: type
                },
                {
                  name: t('productdetails.data_service_status'),
                  value: serviceContract && (
                    <ContractStatus>
                      {serviceContract.status}
                      <StatusMarker
                        color={getContractStatusColor(serviceContract.status)}
                      />
                    </ContractStatus>
                  )
                },
                {
                  name: t('productdetails.data_service_expiration_date'),
                  value:
                    serviceContract &&
                    dateToStringInFormat(serviceContract.endDate, 'yyyy-MM-dd')
                }
              ]}
            />
            {serviceContract.termsAndConditions.length > 0 && (
              <TocContainer>
                <Heading type={Type.h5}>
                  {t('productdetails.toc_links')}
                </Heading>
                {serviceContract.termsAndConditions.map((toc) => (
                  <ProductServiceTermsAndConditions
                    key={toc.id}
                    toc={toc}
                    languageCode={languageCode ?? 'en'}
                  />
                ))}
              </TocContainer>
            )}
            {!isLast && <Divider />}
          </InformationSection>
        );
      })}
    </Container>
  );
};

export default ProductServices;
