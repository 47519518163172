/* istanbul ignore file */
import { FC, Fragment, useEffect, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

import {
  fetchTranslation,
  localizationSelector
} from 'store/localizationState';
import { fetchMarketState } from 'store/marketState';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getMarketSettings } from 'utils/marketSettingUtils';

import Router from 'router';
import Loader from 'components/UI/Loader';
import PageWrapper from 'components/UI/Page/PageWrapper';
import ErrorDisplay from 'components/fragments/ErrorDisplay';

import { GlobalStyles } from 'styles';
import { husqvarnaTheme } from 'styles/themes';
import { useGetConfigQuery } from 'store/configService';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const { isLoadingTranslation } = useAppSelector(localizationSelector);

  // Hooks
  const config = useGetConfigQuery();

  // Fetch market settings
  useEffect(() => {
    const { market, languageCode } = getMarketSettings();
    dispatch(fetchMarketState(market, languageCode));
    dispatch(fetchTranslation());
  }, [dispatch]);

  const content = useMemo(() => {
    if (isLoadingTranslation || config.isLoading) {
      return (
        <PageWrapper empty>
          <Loader center size="large" />
        </PageWrapper>
      );
    }

    return (
      <Fragment>
        <Router />
        <ErrorDisplay />
      </Fragment>
    );
  }, [isLoadingTranslation, config]);

  return (
    <ThemeProvider theme={husqvarnaTheme}>
      <GlobalStyles />
      {content}
    </ThemeProvider>
  );
};

export default App;
