import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';
import { ApiError } from 'models/errors/ApiError';
import * as contractService from 'services/contractService/contractService';
import type { RootState } from 'store';
import { displayApiError } from 'store/globalErrorState/globalErrorState';
import { contractTypes } from 'utils/constants';

export type MarketState = {
  market?: string;
  isLoading?: boolean;
  termsOfUseDocumentUrl: string;
  error: ApiError | null;
};

export const defaultMarketState: MarketState = {
  market: 'gb',
  isLoading: true,
  termsOfUseDocumentUrl: '',
  error: null
};

export const marketSlice = createSlice({
  name: 'market',
  initialState: defaultMarketState,
  reducers: {
    getMarketState: (state) => {
      state.isLoading = true;
    },
    getMarketStateSuccess: (
      state,
      action: PayloadAction<{
        market: string;
        termsOfUseDocumentUrl: string;
      }>
    ) => {
      const { market, termsOfUseDocumentUrl } = action.payload;
      state.market = market;
      state.termsOfUseDocumentUrl = termsOfUseDocumentUrl;
      state.isLoading = false;
    },
    getMarketStateFailure: (state, action: PayloadAction<ApiError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetMarketState() {
      return defaultMarketState;
    }
  }
});

// Export actions
export const {
  getMarketState,
  getMarketStateSuccess,
  getMarketStateFailure,
  resetMarketState
} = marketSlice.actions;

// Export reducer
export const marketReducer = marketSlice.reducer;

// Export selector
export const marketSelector = (state: RootState) => state.market;

// Export thunks
export function fetchMarketState(market: string, languageCode = 'en') {
  return async (dispatch: Dispatch) => {
    dispatch(getMarketState());
    try {
      const termsOfUseDocumentUrl =
        await contractService.getContractDocumentUrl(
          contractTypes.TermsOfUse,
          languageCode
        );

      dispatch(getMarketStateSuccess({ market, termsOfUseDocumentUrl }));
    } catch (e: any) {
      dispatch(getMarketStateFailure(e));
      dispatch(displayApiError(e));
    }
  };
}
