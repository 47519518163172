import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit';

import {
  BasicDataAttributes,
  CountryOption,
  LanguageOption
} from 'models/basicData';
import defaultTranslation from 'assets/languages/en.json';
import { AppTranslation, Translation } from 'utils/constants';
import * as localizationService from 'services/localizationService';
import { determineLanguage } from 'utils/localizationUtils';
import type { RootState } from 'store';

export type LocalizationState = {
  languageCode?: string;
  isLoadingTranslation: boolean;
  translation: AppTranslation;
  isLoadingBasicData: boolean;
  countryOptions: CountryOption[];
  languageOptions: LanguageOption[];
  errors: string[];
};

export const defaultLocalizationState: LocalizationState = {
  isLoadingTranslation: true,
  isLoadingBasicData: false,
  translation: defaultTranslation,
  languageCode: 'en',
  languageOptions: [],
  countryOptions: [],
  errors: []
};

export const localizationSlice = createSlice({
  name: 'localization',
  initialState: defaultLocalizationState,
  reducers: {
    getBasicData: (state) => {
      state.isLoadingBasicData = true;
    },
    getBasicDataSuccess: (
      state,
      action: PayloadAction<BasicDataAttributes>
    ) => {
      const { countries, languages } = action.payload;

      state.countryOptions = countries;
      state.languageOptions = languages;
      state.isLoadingBasicData = false;
    },
    getBasicDataFailure: (state, action: PayloadAction<string>) => {
      state.isLoadingBasicData = false;
      state.errors = [action.payload];
    },
    getTranslation: (state: LocalizationState) => {
      state.isLoadingTranslation = true;
    },
    getTranslationSuccess: (state, action: PayloadAction<Translation>) => {
      const { code, language } = action.payload;

      state.translation = language;
      state.languageCode = code;
      state.isLoadingTranslation = false;
    },
    getTranslationFailure: (state, action: PayloadAction<string>) => {
      state.isLoadingTranslation = false;
      state.errors = [action.payload];
    },
    resetLocalizationState() {
      return defaultLocalizationState;
    }
  }
});

// Export actions
export const {
  getTranslation,
  getTranslationSuccess,
  getTranslationFailure,
  getBasicData,
  getBasicDataSuccess,
  getBasicDataFailure,
  resetLocalizationState
} = localizationSlice.actions;

// Export reducer
export const localizationReducer = localizationSlice.reducer;

// Export selector
export const localizationSelector = (state: RootState) => state.localization;

// Export thunks
export function fetchBasicData() {
  return async (dispatch: Dispatch) => {
    dispatch(getBasicData());
    try {
      const basicData = await localizationService.getBasicData();

      dispatch(getBasicDataSuccess(basicData));
    } catch (e: any) {
      dispatch(getBasicDataFailure(e.message));
    }
  };
}

export function fetchTranslation() {
  return async (dispatch: Dispatch) => {
    dispatch(getTranslation());
    try {
      const langCode = determineLanguage();
      const languageContainer =
        await localizationService.loadLanguage(langCode);
      dispatch(getTranslationSuccess(languageContainer));
    } catch (e: any) {
      dispatch(getTranslationFailure(e.message));
    }
  };
}
