import CountryLanguage from 'cs-alpha-utils/utils/country-language';
import AvailableLanguages from 'assets/languages/available.json';
import {
  defaultLanguageCode,
  supportedNorwegianLangCode,
  unSupportedNorwegianLangCodes,
  defaultCountryCode,
  nonStandardLangCodes
} from '../constants';
import { isString } from '../stringUtils';

/**
 * Checks if a countryCode format is correct. Should be two characters in uppercase.
 */
function isCountryCodeInCorrectFormat(countryCode: string): boolean {
  if (!isString(countryCode) || countryCode.length !== 2) {
    return false;
  }

  return countryCode.toUpperCase() === countryCode;
}

/**
 * Checks if a languageCode format is correct. Should be two characters in lowercase.
 */
function isLanguageCodeInCorrectFormat(languageCode: string): boolean {
  if (!isString(languageCode) || languageCode.length !== 2) {
    return false;
  }

  return languageCode.toLowerCase() === languageCode;
}

/**
 * Checks if a country code is supported
 */
export function isCountryCode(countryCode = ''): boolean {
  return (
    isCountryCodeInCorrectFormat(countryCode) &&
    CountryLanguage.isCountryCode(countryCode)
  );
}

/**
 * Checks if a language code is supported
 */
export function isLanguageCode(languageCode = ''): boolean {
  return (
    isLanguageCodeInCorrectFormat(languageCode) &&
    CountryLanguage.isLanguageCode(languageCode)
  );
}

export function getCountryCodeOrDefault(countryCode: string): string {
  return isCountryCode(countryCode) ? countryCode : defaultCountryCode;
}

export function getLanguageCodeOrDefault(languageCode: string): string {
  return isLanguageCode(languageCode) ? languageCode : defaultLanguageCode;
}

/**
 * Finding the first language in the Navigator list that
 * matches our available languages. Converting Navigator
 * codes to ensure that the return value is of format matching
 * available language.
 */
export function getLanguageByUserAgentOrDefault(
  agentLanguages = window.navigator.languages
): string {
  if (!Array.isArray(agentLanguages)) {
    console.warn(
      `Could not find any Navigator languages. Defaulting to ${defaultLanguageCode}`
    );
    return defaultLanguageCode;
  }

  // Check for non-standard language codes first
  const nonStandardLangCode = agentLanguages.find((lang) =>
    nonStandardLangCodes.includes(lang.toLowerCase())
  );

  if (nonStandardLangCode) {
    return nonStandardLangCode.toLowerCase();
  }

  const found = agentLanguages.find((lang) => {
    // Splitting possible lang values like en-US to get an isolated language code
    const [isoLanguage] = lang.split('-');

    return AvailableLanguages.includes(isoLanguage);
  });

  if (!found) {
    console.warn(
      `Navigator languages are not supported. Defaulting to ${defaultLanguageCode}`
    );

    return defaultLanguageCode;
  }

  return found.split('-')[0];
}

export function determineLanguage(location: Location = window.location) {
  const params = new URLSearchParams(location.search);
  let langCode = params.get('language') ?? getLanguageByUserAgentOrDefault();

  // Converting unsupported norwegian lang codes to supported one
  if (unSupportedNorwegianLangCodes.includes(langCode)) {
    langCode = supportedNorwegianLangCode;
  }

  return langCode;
}
