import { createSlice, Dispatch } from '@reduxjs/toolkit';
import * as customerService from 'services/customerService/customerService';
import type { RootState } from 'store';
import { displayApiError } from '../globalErrorState/globalErrorState';

export type PasswordResetState = {
  completed: boolean;
  isLoading: boolean;
  hasErrors: boolean;
};

export const defaultPasswordResetState: PasswordResetState = {
  completed: false,
  isLoading: false,
  hasErrors: false
};

const passwordResetSlice = createSlice({
  name: 'password-reset',
  initialState: defaultPasswordResetState,
  reducers: {
    passwordResetStart: (state) => {
      state.isLoading = true;
      state.hasErrors = false;
      state.completed = false;
    },
    passwordResetSuccess: (state) => {
      state.isLoading = false;
      state.hasErrors = false;
      state.completed = true;
    },
    passwordResetFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
      state.completed = false;
    }
  }
});

// Export actions
export const {
  passwordResetStart,
  passwordResetSuccess,
  passwordResetFailure
} = passwordResetSlice.actions;

// Export reducer
export const passwordResetReducer = passwordResetSlice.reducer;

// Export selector
export const passwordResetSelector = (state: RootState) => state.passwordReset;

// Export thunks
export function resetPassword(email: string) {
  return async (dispatch: Dispatch) => {
    dispatch(passwordResetStart());
    try {
      await customerService.resetPasswordByEmail(email);
      dispatch(passwordResetSuccess());
    } catch (e: any) {
      dispatch(displayApiError(e));
      dispatch(passwordResetFailure());
    }
  };
}
