import { FC, MouseEvent, useCallback, useMemo } from 'react';

import { truncate } from 'utils/stringUtils';
import { useTranslations } from 'hooks/useTranslations';
import * as config from 'config';

import { Product } from 'models/product';
import Button from 'components/UI/Buttons/Button';

import {
  Card,
  CardHeader,
  CardActions,
  CardTitleCategory,
  CardImageContainer,
  CardData,
  CardTitleModelName,
  CardTitleDescription
} from './styled';

type Props = {
  product: Product;
  husqvarnaId?: string;
  hasOffers?: boolean;
  onClick?: () => void;
};

const ProductCard: FC<Props> = ({
  product: {
    iprId,
    productCategory,
    modelName,
    description,
    imageUrl,
    pnc = '–',
    serialNumber = '–'
  },
  husqvarnaId,
  hasOffers,
  onClick
}) => {
  const t = useTranslations();

  // Redirect to offer
  const onOfferClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      window.open(
        `${config.keylightUrl}/shop/service-contracts?iprId=${iprId}`
      );
    },
    [iprId]
  );

  const cardData = useMemo(() => {
    if (husqvarnaId) {
      return [
        {
          id: 1,
          title: t('productinfo.hid'),
          value: husqvarnaId
        }
      ];
    }

    return [
      {
        id: 1,
        title: t('productinfo.product_number'),
        value: pnc
      },
      {
        id: 2,
        title: t('productinfo.serial_number'),
        value: serialNumber
      }
    ];
  }, [husqvarnaId, pnc, serialNumber, t]);

  return (
    <Card onClick={onClick} data-testid="product-card">
      {imageUrl && (
        <CardImageContainer>
          <img src={imageUrl} alt={modelName} />
        </CardImageContainer>
      )}
      <CardHeader>
        <div>
          <CardTitleCategory>
            {productCategory?.toLowerCase()}
          </CardTitleCategory>
          <CardTitleModelName>{modelName}</CardTitleModelName>
          <CardTitleDescription>{truncate(description)}</CardTitleDescription>
        </div>
      </CardHeader>
      <CardData>
        {cardData.map((item) => (
          <div key={item.id}>
            <h6>{item.value}</h6>
            <p>{item.title}</p>
          </div>
        ))}
      </CardData>
      {hasOffers && (
        <CardActions>
          <Button size="medium" color="success" onClick={onOfferClick}>
            {t('myproducts.btn_offers')}
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default ProductCard;
