import { FC, useCallback, useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { resetPassword, passwordResetSelector } from 'store/passwordResetState';

import { useQuery } from 'hooks/useQuery';
import { useTranslations } from 'hooks/useTranslations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { mailRegex } from 'utils/constants';
import { showSuccessToast } from 'utils/toastUtils';

import Loader from 'components/UI/Loader';
import SeoHelmet from 'components/fragments/SeoHelmet';
import Button from 'components/UI/Buttons/Button';
import InputField from 'components/UI/InputField';
import PageHeader from 'components/UI/Page/PageHeader';
import PageImageWrapper from 'components/UI/Page/PageImageWrapper';
import BackButton from 'components/UI/Buttons/BackButton';

import { Form, LoaderGrid } from './styled';

const ResetPasswordPage: FC = () => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const { completed, isLoading } = useAppSelector(passwordResetSelector);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  const [email] = useQuery('email');

  // Email query params
  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [setValue, email]);

  // Show toast when completed
  useEffect(() => {
    if (completed) {
      showSuccessToast(t('resetpassword.email_verification'));
    }
  }, [completed, t]);

  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    ({ email }) => {
      dispatch(resetPassword(email));
    },
    [dispatch]
  );

  // Reset inputfield
  const reset = useCallback(
    (name: string) => {
      setValue(name, '');
      clearErrors(name);
    },
    [clearErrors, setValue]
  );

  return (
    <PageImageWrapper>
      <SeoHelmet title="resetpassword.meta_title" />
      <BackButton>{t('productinfo.btn_back')}</BackButton>
      <PageHeader
        title={t('resetpassword.title')}
        subtitle={t('resetpassword.description')}
      />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          name="email"
          disabled={isLoading}
          description="input.email_label"
          error={errors.email}
          register={register('email', {
            required: {
              value: true,
              message: 'input.email_required'
            },
            pattern: {
              value: mailRegex,
              message: 'input.email_invalid'
            }
          })}
          reset={reset}
        />
        <Button disabled={isLoading} submit fullWidth>
          {t('resetpassword.btn_reset')}
        </Button>
        <LoaderGrid>{isLoading && <Loader size="small" />}</LoaderGrid>
      </Form>
    </PageImageWrapper>
  );
};

export default ResetPasswordPage;
