import { FC, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { dateToStringInFormat } from 'cs-alpha-utils/utils/date-utils';

import { useAppDispatch } from 'hooks/redux';
import { useTranslations } from 'hooks/useTranslations';
import { MenuItemVisibility } from 'models/layout';
import { AuthSession } from 'models/auth';
import { Invite } from 'models/invite';
import * as routes from 'router/routes';
import { logout } from 'store/authState';
import { setInvite } from 'store/inviteState';
import { logoPaths } from 'styles';
import { externalMenuLinks, menuLinks } from 'utils/constants';

import Button from 'components/UI/Buttons/Button';
import Heading, { Type } from 'components/UI/Heading';
import HamburgerButton from 'components/UI/Buttons/HamburgerButton';
import DataCell from 'components/UI/DataCell';
import Drawer from 'components/UI/Drawer';
import Icon, { IconType } from 'components/UI/Icon';
import PageState from 'components/UI/Page/PageState';

import arrowRightIcon from 'assets/vectors/arrow-right.svg';

import {
  NavbarMargin,
  NavbarContainer,
  NavbarCol,
  Logo,
  LogoLink,
  InboxButton,
  InboxGrid,
  InboxAlert,
  MenuGrid,
  LinkRow,
  LinkExternal,
  ActionRow,
  ArrowIcon,
  InboxInvite,
  InboxProduct,
  InboxProductGrid,
  InboxProductDetails,
  InboxProductButtons,
  InviteContainer,
  InboxProductImage
} from './styled';

type Props = {
  invites: Invite[];
  session: AuthSession;
};

const Navbar: FC<Props> = ({ invites, session }) => {
  const t = useTranslations();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authenticated, accessToken } = session;

  // State
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [inboxIsOpen, setInboxIsOpen] = useState<boolean>(false);

  // Logout
  const onLogout = useCallback(() => {
    if (authenticated && accessToken) {
      dispatch(logout(accessToken));
    }
    setMenuIsOpen(false);
    setInboxIsOpen(false);
  }, [dispatch, authenticated, accessToken]);

  // Register invite
  const onRegisterInvite = useCallback(
    (data: Invite) => () => {
      dispatch(setInvite(data));
      navigate(routes.INVITE_PRODUCTS_REG);
      setInboxIsOpen(false);
    },
    [dispatch, navigate]
  );

  // Actions
  const onOpenInbox = useCallback(() => setInboxIsOpen(true), []);
  const onCloseMenu = useCallback(() => setMenuIsOpen(false), []);
  const onCloseInbox = useCallback(() => setInboxIsOpen(false), []);

  // Hide menu item
  const hideMenuItem = useCallback(
    (visibility: MenuItemVisibility) => {
      switch (visibility) {
        case MenuItemVisibility.Private:
          return !authenticated;
        case MenuItemVisibility.Public:
          return authenticated;
        default:
          return false;
      }
    },
    [authenticated]
  );

  const inviteDrawer = useMemo(() => {
    if (!invites.length) {
      return (
        <PageState
          iconType={IconType.Register}
          title={t('invites.empty_title')}
        />
      );
    }

    return invites.map((invite) => (
      <InboxInvite key={invite.id}>
        {invite.soldDate &&
          `${t('invites.sold_date')}: ${dateToStringInFormat(
            invite.soldDate,
            'yyyy-MM-dd'
          )}`}
        {invite.products.map((product) => (
          <InboxProduct key={product.iprId}>
            {product.imageUrl && (
              <InboxProductImage
                src={product.imageUrl}
                alt={product.modelName}
              />
            )}
            <InboxProductGrid>
              <Heading type={Type.h3}>{product.modelName}</Heading>
              <InboxProductDetails>
                <DataCell name={t('invites.pnc_no')}>{product.pnc}</DataCell>
                <DataCell name={t('invites.serial_no')}>
                  {product.serialNumber}
                </DataCell>
              </InboxProductDetails>
            </InboxProductGrid>
          </InboxProduct>
        ))}
        <InboxProductButtons>
          <Button color="success" onClick={onRegisterInvite(invite)}>
            {t('invites.btn_register')}
          </Button>
        </InboxProductButtons>
      </InboxInvite>
    ));
  }, [onRegisterInvite, t, invites]);

  return (
    <>
      <NavbarMargin />
      <NavbarContainer>
        <NavbarCol>
          <HamburgerButton setIsOpen={setMenuIsOpen} isOpen={menuIsOpen} />
        </NavbarCol>
        <LogoLink to={routes.ROOT}>
          <Logo src={logoPaths.husqvarna} alt="Logo" />
        </LogoLink>
        <NavbarCol $alignRight>
          {authenticated && (
            <InboxButton role="button" onClick={onOpenInbox}>
              <InboxGrid>
                {invites.length > 0 && (
                  <InboxAlert>{invites.length}</InboxAlert>
                )}
                <Icon type={IconType.Register} />
              </InboxGrid>
            </InboxButton>
          )}
        </NavbarCol>
      </NavbarContainer>
      <Drawer isOpen={menuIsOpen} onClose={onCloseMenu} title={t('menu.title')}>
        <MenuGrid>
          {menuLinks.map(({ visibility, name, link }) => {
            if (hideMenuItem(visibility)) {
              return null;
            }

            return (
              <LinkRow to={link} key={name} onClick={onCloseMenu}>
                {t(name)}
                <ArrowIcon src={arrowRightIcon} alt="arrow right" />
              </LinkRow>
            );
          })}
          {authenticated && (
            <ActionRow onClick={onLogout}>{t('menu.logout')}</ActionRow>
          )}
        </MenuGrid>
        <MenuGrid>
          {externalMenuLinks.map(({ name, link }) => (
            <LinkExternal
              key={name}
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              {t(name)}
            </LinkExternal>
          ))}
        </MenuGrid>
      </Drawer>
      <Drawer
        isOpen={inboxIsOpen}
        onClose={onCloseInbox}
        position="right"
        title={t('invites.title')}
      >
        <InviteContainer>{inviteDrawer}</InviteContainer>
      </Drawer>
    </>
  );
};

export default Navbar;
