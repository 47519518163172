import * as config from 'config';
import {
  AuthTokens,
  AuthTokensResponse,
  ValidateTokenResponse
} from 'models/auth';
import { apiFetch, defaultHeaders } from 'utils/fetchUtils';

export function loginRedirect(
  languageCode: string,
  email?: string | null,
  resetSession = false
): void {
  const redirectUri = `${config.backendApi}/oauth2/authorization-code`;
  const params = `?client_id=${config.authClientId}&redirect_uri=${redirectUri}&language=${languageCode}`;
  let url = `${config.authUrl}/oauth2/authorize${params}`;

  if (email) {
    url += `&username=${encodeURIComponent(email)}`;
  }

  if (resetSession) {
    url += `&reset-session=true`;
  }

  window.location.assign(url);
}

export async function validateToken(
  accessToken: string
): Promise<ValidateTokenResponse> {
  const url = `${config.backendApi}/oauth2/validate`;

  const validateTokenResponse = await apiFetch<ValidateTokenResponse>(url, {
    headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` }
  });

  return validateTokenResponse;
}

export async function loginWithRefreshToken(
  refreshToken: string
): Promise<AuthTokens> {
  const url = `${config.backendApi}/oauth2/refresh-token`;

  const json = await apiFetch<AuthTokensResponse>(url, {
    headers: { ...defaultHeaders },
    method: 'POST',
    body: JSON.stringify({
      refreshToken
    })
  });

  return json.data;
}

export async function logoutWithToken(accessToken: string): Promise<void> {
  const url = `${config.backendApi}/oauth2/logout`;

  await apiFetch<AuthTokensResponse>(url, {
    headers: { ...defaultHeaders, Authorization: `Bearer ${accessToken}` },
    method: 'POST',
    body: JSON.stringify({
      accessToken
    })
  });
}
