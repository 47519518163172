import { dateToStringInFormat } from 'cs-alpha-utils/utils/date-utils';

export function getDateFromWarrantyDate(dateString: string) {
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(4, 6)) - 1;
  const day = parseInt(dateString.substring(6, 8));
  return new Date(year, month, day);
}

export function formatDate(
  date: number,
  dateFormat = 'yyyy-MM-dd HH:mm'
): string {
  // Convert unix timeStamp to ms
  const timeStamp = date.toString().length === 10 ? date * 1000 : date;
  return dateToStringInFormat(timeStamp, dateFormat);
}
