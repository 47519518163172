import { FC, useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import PageState from 'components/UI/Page/PageState';
import { useTranslations } from 'hooks/useTranslations';
import Button from 'components/UI/Buttons/Button';
import { IconType } from 'components/UI/Icon';

import { ButtonGrid } from './styled';

type FallbackProps = {
  resetError: () => void;
};

const FallbackError: FC<FallbackProps> = ({ resetError }) => {
  const t = useTranslations();
  const onClick = useCallback(() => resetError(), [resetError]);

  return (
    <PageState
      title={t('errors.crash_title')}
      description={t('errors.crash_description')}
      iconType={IconType.Error}
    >
      <ButtonGrid>
        <Button onClick={onClick}>{t('errors.crash_reset')}</Button>
      </ButtonGrid>
    </PageState>
  );
};

const fallBack = (props: FallbackProps) => (
  <FallbackError resetError={props.resetError} />
);

const ErrorBoundary: FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={fallBack} showDialog>
      <Outlet />
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
